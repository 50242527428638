import React, { Suspense, useState, useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import Join from "../pages/Join/Join";
import DiscountCoupons from "../pages/Admin/DiscountCodes/DiscountCodes";
import Subscriptions from "../pages/Admin/Subscriptions/Subscriptions";
import LearnMore from "../pages/LearnMore/LearnMore";
import SearchResults from "../pages/SearchResults/Container";
import Resources from "../pages/Resources/Resources";
import AdvisorProfile from "../pages/AdvisorProfile/Container";
import SwitchClientType from "../pages/SwitchClientType/SwitchClientType";
import FAQ from "../pages/FAQ/FAQ";
import ContactUs from "../pages/ContactUs/ContactUs";
import Admin from "../pages/Admin/Dashboard/Container";
import Header from "../pages/_shared/header";
import Login2 from "../pages/Login/Login";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
//import FADashboard from "../pages/FADashboard/Container";
import FADashboard2 from "../pages/FADashboard2/Container";
import Spinner from "../components/Spinner/Spinner";
import FindingTheRigthAdvisorBlogPost from "../pages/BlogPosts/finding-the-right-advisor-to-help-align-your-investments-with-your-values";
import FiveQuestionsToAskYourAdvisorBlogPost from "../pages/BlogPosts/5-questions-to-ask-your-advisor-about-values-aligned-investing";
import FindAdvisorWithConfidenceBlogPost from "../pages/BlogPosts/find-advisor-with-confidence";
import AvaluesAdvisorSuccessStoryBlogPost from "../pages/BlogPosts/a-valuesadvisor-success-story";
import OurFoundersStoryBlogPost from "../pages/BlogPosts/our-founders-story";

import { AuthContext } from "../Context/AuthContext";
//import { DevOnly } from "../HoC/DevOnly";
//import { ProdOnly } from "../HoC/ProdOnly";
import AffinityGroups from "../pages/Admin/AffinityGroups/AffinityGroups";
import OfficeHoursBanner from "../components/_shared/OfficeHoursBanner/OfficeHoursBanner";
function AdminRoute(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/admin-affinity-groups" component={AffinityGroups}></Route>
        <Route path="/admin-subscriptions" component={Subscriptions}></Route>
        <Route
          path="/admin-discount-coupons"
          component={DiscountCoupons}
        ></Route>
        <Route path="/admin" component={Admin} />{" "}
        <Route path="/about" component={AboutUs} />{" "}
        <Route path="/profile/:id" component={AdvisorProfile} />
        <Route path="/profile-edit" sensitive={true} component={FADashboard2} />
        <Route
          render={(props) => {
            return <Redirect to="/admin" />;
          }}
        />{" "}
        <Route
          path="/logout"
          render={(props) => {
            return <Redirect push to={`/`} />;
          }}
        />{" "}
      </Switch>{" "}
    </React.Fragment>
  );
}

function AdvisorRoute(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/my-profile/:id" component={AdvisorProfile} />{" "}
        <Route path="/about" component={AboutUs} />{" "}
        <Route path="/learn-more" component={LearnMore}></Route>
        <Route path="/resources" component={Resources}></Route>
        <Route path="/contact" component={ContactUs} />{" "}
        <Route path="/recommend" component={ContactUs} />{" "}
        <Route path="/switch-client-type" component={SwitchClientType} />{" "}
        <Route path="/faq" component={FAQ} />
        <Route path="/profile-edit" component={FADashboard2} />
        <BlogPosts></BlogPosts>
        <Redirect push to={`/`} />;
      </Switch>{" "}
    </React.Fragment>
  );
}
function BlogPosts(props) {
  return (
    <>
      <Route
        path="/blog/finding-the-right-advisor-to-help-align-your-investments-with-your-values"
        component={FindingTheRigthAdvisorBlogPost}
      />{" "}
      <Route
        path="/blog/5-questions-to-ask-your-advisor-about-values-aligned-investing"
        component={FiveQuestionsToAskYourAdvisorBlogPost}
      />{" "}
      <Route
        path="/blog/find-advisor-with-confidence"
        component={FindAdvisorWithConfidenceBlogPost}
      />{" "}
      <Route
        path="/blog/a-valuesadvisor-success-story"
        component={AvaluesAdvisorSuccessStoryBlogPost}
      />{" "}
      <Route
        path="/blog/our-founders-story"
        component={OurFoundersStoryBlogPost}
      />{" "}
    </>
  );
}
function CommonRoute(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route
          path="/getstarted"
          render={() => {
            return <Join createAccount={true}></Join>;
          }}
        />
        <Route
          path="/logout"
          render={(props) => {
            return <Redirect push to={`/`} />;
          }}
        />
        <Route path="/about" component={AboutUs} />{" "}
        <Route path="/join" component={Join} />{" "}
        <Route path="/learn-more" component={LearnMore}></Route>
        <Route path="/contact" component={ContactUs} />{" "}
        <Route path="/recommend" component={ContactUs} />{" "}
        <Route path="/login" component={Login2}></Route>
        <Route path="/reset-password" component={ResetPassword}></Route>
        <Route path="/forgot-password" component={ForgotPassword}></Route>
        <Route path="/resources" component={Resources}></Route>
        <Route path="/faq" component={FAQ} />
        <Route path="/profile-edit" component={FADashboard2} />
        <Route
          path="/createaccount"
          render={() => {
            return <Join createAccount={true}></Join>;
          }}
        />
        <BlogPosts></BlogPosts>
      </Switch>{" "}
    </React.Fragment>
  );
}

function VisitorRoute(props) {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/search" component={SearchResults} />{" "}
        <Route path="/profile/:id" component={AdvisorProfile} />{" "}
        <Route path="/about" component={AboutUs} />{" "}
        <Route path="/contact" component={ContactUs} />{" "}
        <Route path="/recommend" component={ContactUs} />{" "}
        <Route path="/join" component={Join} />{" "}
        <Route path="/learn-more" component={LearnMore}></Route>
        <Route path="/resources" component={Resources}></Route>
        <Route path="/switch-client-type" component={SwitchClientType} />{" "}
        <Route path="/faq" component={FAQ} /> <BlogPosts></BlogPosts>
        <Route
          path="/logout"
          render={(props) => {
            return <Redirect push to={`/`} />;
          }}
        />
        <Redirect push to={`/`} />
      </Switch>
    </React.Fragment>
  );
}
const Routes = (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const authContext = useContext(AuthContext);
  //const { authState } = useAuth();
  //const { role, isLoggedIn } = authState;
  const { role, isLoggedIn } = authContext;
  const routesMap = {
    admin: <AdminRoute {...props} />,
    advisor: <AdvisorRoute {...props} />,
    visitor: <VisitorRoute {...props} />,
  };
  const RouteToRender = isLoggedIn ? (
    routesMap[role]
  ) : (
    <CommonRoute {...props} />
  );
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      {" "}
      <React.Fragment>
        <div className="footer-position-fix">
          <OfficeHoursBanner
            showBanner={true}
            tillDate={new Date("2024-07-19")}
            text={
              "Need help? Join our live team members on Thursday, July 18th at 10am PT and 1pm ET"
            }
          ></OfficeHoursBanner>
          <Header />{" "}
          {isMounted ? (
            <React.Fragment>
              {RouteToRender}
              <Route exact path="/" component={Home} />
            </React.Fragment>
          ) : (
            <Spinner />
          )}{" "}
        </div>{" "}
      </React.Fragment>{" "}
    </Suspense>
  );
};
export default Routes;
